<template>
  <slide-over-right :id="sorId" editType="pass" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center w-1/2 panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6 mt-2">
      <pass-card
        :minuteAmountOfPass="form.free_riding_time_minutes"
        :currencySymbol="currencySymbol"
        :passPrice="form.price"
        :days="form.period"
        :passCategory="form.category"
        :isUnlockDiscountApplied="!!show_unlock_charge"
        :unlockDiscount="form.unlock_charge"
        :isReservationDiscountApplied="!!show_reservation_charge"
        :reservationDiscount="form.reservation_charge"
        :isMinuteChargeDiscountApplied="!!show_per_minute_charge"
        :perMinuteChargeDiscount="form.per_minute_charge"
        :isFreeMinutesPerRideApplied="!!show_free_minutes_each_ride"
        :freeMinutesPerRide="form.free_minutes_each_ride"
        :passStartDate="getPassStartDate"
        :passExpiredDate="getPassExpiredDate"
      />
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
          class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
        >
          <div class="flex items-center pt-8">
            <!-- start: name & phone -->
            <div class="w-1/2">
              <AppInput
                type="richselect"
                :name="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionValueType'
                  )
                "
                :label="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionValueType'
                  )
                "
                :placeholder="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.subscriptionValueType'
                  )
                "
                :infoDescription="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.infoDescription.subscriptionValueType'
                  )
                "
                :options="passCategoryChoices"
                hide-search-box
                rules="required"
                v-model="form.category"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                type="richselect"
                :name="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionType'
                  )
                "
                :label="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionType'
                  )
                "
                :placeholder="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.subscriptionType'
                  )
                "
                :options="passTypes"
                hide-search-box
                rules="required"
                v-model="form.period"
              />
            </div>
          </div>

          <div>
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.assignedFleet'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.assignedFleet'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.assignedFleet'
                )
              "
              :options="getFleetOptions"
              hide-search-box
              rules="required"
              v-model="form.assigned_fleet"
            />
          </div>

          <div>
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.vehicleType'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.vehicleType'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.vehicleType'
                )
              "
              :options="vehicleTypes"
              hide-search-box
              rules="required"
              v-model="form.vehicle_type"
            />
          </div>

          <div v-if="form.category === 1">
            <AppInput
              type="number"
              rules="required"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.ridingTime'
                )
              "
              :infoDescription="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.infoDescription.ridingTime'
                )
              "
              placeholder="e.g. 60"
              v-model="form.free_riding_time_minutes"
              :haveUnitText="true"
              unitText="Min"
            />
          </div>

          <div>
            <AppInput
              type="number"
              step="0.01"
              rules="required"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionPrice'
                )
              "
              placeholder="e.g. 49.99"
              v-model="form.price"
              :haveUnitText="true"
              :unitText="currencySymbol"
            />
          </div>

          <div class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.status'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.status'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.status'
                )
              "
              :options="statusOptions"
              hide-search-box
              rules="required"
              v-model="is_active"
            />
          </div>

          <div v-if="form.category === 0" class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockChargeAllowed'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockChargeAllowed'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountUnlockChargeAllowed'
                )
              "
              :options="unLockCharge"
              hide-search-box
              v-model="show_unlock_charge"
            />
          </div>

          <div
            v-if="form.category === 0 && show_unlock_charge == 1"
            class="flex items-center pt-4"
          >
            <AppInput
              type="number"
              step="0.01"
              rules="required|between:0,100"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockCharge'
                )
              "
              placeholder="e.g. 3"
              v-model="form.unlock_charge"
              :haveUnitText="true"
              unitText="%"
            />
          </div>

          <div v-if="form.category === 0" class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationChargeAllowed'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationChargeAllowed'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountReservationChargeAllowed'
                )
              "
              :options="reservationCharge"
              hide-search-box
              v-model="show_reservation_charge"
            />
          </div>

          <div
            v-if="form.category === 0 && show_reservation_charge == 1"
            class="flex items-center pt-4"
          >
            <AppInput
              type="number"
              step="0.01"
              rules="required|between:0,100"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationCharge'
                )
              "
              placeholder="e.g. 3"
              v-model="form.reservation_charge"
              :haveUnitText="true"
              unitText="%"
            />
          </div>

          <div v-if="form.category === 0" class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinutesChargeAllowed'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinutesChargeAllowed'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountPerMinutesChargeAllowed'
                )
              "
              :options="perCharges"
              hide-search-box
              v-model="show_per_minute_charge"
            />
          </div>

          <div
            v-if="form.category === 0 && show_per_minute_charge == 1"
            class="flex items-center pt-4"
          >
            <AppInput
              type="number"
              step="0.01"
              rules="required|between:0,100"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinuteCharge'
                )
              "
              placeholder="e.g. 15"
              v-model="form.per_minute_charge"
              :haveUnitText="true"
              unitText="%"
            />
          </div>
          <div class="mt-2">
            <TCheckbox
              v-model="form.is_minimum_fee_waived"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.disableMinimumFeeForRide'
                )
              "
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.disableMinimumFeeForRide'
                )
              "
              wrapped
            />
          </div>

          <div v-if="form.category === 0" class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeAllowed'
                )
              "
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeAllowed'
                )
              "
              :placeholder="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.freeTimeAllowed'
                )
              "
              :options="perCharges"
              hide-search-box
              v-model="show_free_minutes_each_ride"
            />
          </div>

          <div
            v-if="form.category === 0 && show_free_minutes_each_ride == 1"
            class="flex items-center pt-4"
          >
            <AppInput
              type="number"
              rules="required|between:0,100000"
              :label="
                $t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeEachRide'
                )
              "
              placeholder="e.g. 30"
              v-model="form.free_minutes_each_ride"
              :haveUnitText="true"
              unitText="Min"
            />
          </div>

          <div class="flex items-center pt-6">
            <div class="pr-2 w-1/2">
              <AppInput
                type="date"
                rules="required"
                :label="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionStartDate'
                  )
                "
                v-model="start_date"
                v-on:input="onChangeStartDate"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                type="date"
                rules="required"
                :label="
                  $t(
                    'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionExpireDate'
                  )
                "
                v-model="end_date"
                v-on:input="onChangeExpireDate"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">{{
            $t('components.stepNavigation.cancel')
          }}</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">{{
          actionButtonText
        }}</t-button>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { PassConfig } from '@/config/PassConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import xMan from '@/utils/xMan'
import PassCard from '@/components/cards/PassCard.vue'
import { getDate } from '@/utils'
import { convertToTitleCase } from '@/components/picker/date-range/utils/CollectionUtils'
export default {
  name: 'AddPass',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
    PassCard,
  },
  data() {
    return {
      sorId: PassConfig.events.sorId,
      isLoading: false,
      form: {},
      passCategoryChoices: [
        {
          value: 0,
          text: 'Subscription Prime (Credit)',
        },
        {
          value: 1,
          text: 'Subscription (Time)',
        },
      ],
      passTypes: [
        {
          value: 1,
          text: '1 Day',
        },
        {
          value: 3,
          text: '3 Days',
        },
        {
          value: 7,
          text: '7 Days',
        },
        {
          value: 15,
          text: '15 Days',
        },
        {
          value: 30,
          text: '30 Days',
        },
        {
          value: 90,
          text: '90 Days',
        },
        {
          value: 180,
          text: '180 Days',
        },
        {
          value: 365,
          text: '365 Days',
        },
      ],

      vehicleTypes: [],
      unLockCharge: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      reservationCharge: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      statusOptions: [
        {
          value: false,
          text: 'Inactive',
        },
        {
          value: true,
          text: 'Active',
        },
      ],
      perCharges: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      show_unlock_charge: 1,
      show_reservation_charge: 1,
      show_per_minute_charge: 1,
      show_free_minutes_each_ride: 1,
      fleets: [],
      cardTitle: '',
      is_active: true,
      start_date: '',
      end_date: '',
      item: {},
      currencySymbol: '--',
    }
  },
  async created() {
    this.fleets = await this.$http
      .get('/dashboard/fleets/?dropdown')
      .then((res) => res.data.data)
      .catch((err) => console.log('fleetListErr', err))
    this.currencySymbol = this.$org.default_currency.symbol

    const vehicleTypeMap = {
      'SCOOTER': {
        text: 'Scooter',
        value: 'P',
      },
      'SCOOTER PRO': {
        text: 'Scooter Pro',
        value: 'PP',
      },
      'EBIKE': {
        text: 'E-Bike',
        value: 'E',
      },
      'BIKE': {
        text: 'Bike',
        value: 'S',
      },
      'COCO': {
        text: 'SuperCoco',
        value: 'C',
      },
      'KENOTA': {
        text: 'Kenota',
        value: 'K',
      },
      'MOPED': {
        text: 'Moped',
        value: 'M',
      },
    }
    const orgVehicleTypes = this?.$org?.vehicle_type || []
    orgVehicleTypes.forEach((t) => this.vehicleTypes.push(vehicleTypeMap[t]))

    // this.passCategoryChoices = await this.$http
    //   .get(useEndpoints.pass.passCategoryChoices())
    //   .then((res) => res.data)
    //   .catch((err) => console.log('passCategoryChoicesErr', err))
  },
  mounted() {
    EventBus.$on(PassConfig.events.editingData, (item) => {
      this.item = item
      this.form = {}

      if (Object.keys(this.item).length > 1) {
        //edit
        this.getPassDataByID(item.id)
      } else {
        //add
        this.init()
      }
    })
  },
  computed: {
    titleText() {
      return this.isEditing
        ? this.$t('components.subscriptionManagement.addEdit.headline.update')
        : this.$t('components.subscriptionManagement.addEdit.headline.add')
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    actionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    getFleetOptions() {
      return this.fleets.map((fleet) => ({
        text: `${fleet.name} (${fleet.country.code3})`,
        value: fleet.id,
      }))
    },
    getPassStartDate() {
      return this.getDate(this.start_date)
    },
    getPassExpiredDate() {
      return this.getDate(this.end_date)
    },
  },
  watch: {
    'form.assigned_fleet': {
      immediate: true,
      deep: false,
      handler(val) {
        if (val) {
          const filteredData = this.fleets.find((item) => item.id === val)
          this.currencySymbol = filteredData.country.currency_symbol
        }
      },
    },
  },
  methods: {
    getDate,
    init() {
      this.form = {
        category: 0,
        period: 3,
        unlock_charge: 100,
        vehicle_type: 'A',
        profile_type: 'A',
        pass_type: 'DD',
        is_minimum_fee_waived: false,
      }
      this.start_date = ''
      this.end_date = ''
      this.show_unlock_charge = 1
      this.show_reservation_charge = 1
      this.show_per_minute_charge = 1
      this.show_free_minutes_each_ride = 1
    },
    submit() {
      this.$refs.submitButton.click()
    },
    showModal() {
      dispatchEvent(new Event(PassConfig.events.sorToggle))
    },

    onChangeStartDate(event) {
      this.start_date = event
      this.getValidateDays()
    },
    onChangeExpireDate(event) {
      this.end_date = event
      this.getValidateDays()
    },
    getValidateDays() {
      if (this.start_date != '' && this.end_date != '') {
        let date1 = new Date(this.start_date)
        let date2 = new Date(this.end_date)

        var Difference_In_Time = date2.getTime() - date1.getTime()
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
        if (Difference_In_Days >= 0) {
          this.validate_day = Difference_In_Days + ' days'
        } else {
          this.start_date = ''
          this.end_date = ''
        }
      }
    },
    getPassDataByID(id) {
      this.$http
        .get(PassConfig.api.detail(id))
        .then((res) => {
          var result = res.data
          this.form = {
            category: result.category,
            price: result.amount,
            per_minute_charge: result.per_minute_charge,
            reservation_charge: result.reservation_charge,
            unlock_charge: result.unlock_charge,
            period: result.period,
            profile_type: result.profile_type,
            vehicle_type: result.vehicle_type,
            free_minutes_each_ride: result.free_minutes_each_ride,
            free_riding_time_minutes: result.free_riding_time_minutes,
            assigned_fleet: result.assigned_fleet?.id ?? null,
            is_minimum_fee_waived: result.is_minimum_fee_waived ?? false,
          }
          this.is_active = result.is_active
          this.show_free_minutes_each_ride =
            result.free_minutes_each_ride &&
            result.free_minutes_each_ride != null
              ? 1
              : 0

          this.show_unlock_charge =
            result.unlock_charge && result.unlock_charge != null ? 1 : 0
          this.show_reservation_charge =
            result.reservation_charge && result.reservation_charge != null
              ? 1
              : 0
          this.show_per_minute_charge =
            result.per_minute_charge != '' && result.per_minute_charge != null
              ? 1
              : 0
          this.start_date = result.start_date
            ? result.start_date.split('/')[2] +
              '-' +
              result.start_date.split('/')[1] +
              '-' +
              result.start_date.split('/')[0]
            : ''
          this.end_date = result.end_date
            ? result.end_date.split('/')[2] +
              '-' +
              result.end_date.split('/')[1] +
              '-' +
              result.end_date.split('/')[0]
            : ''
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async confirmSubmit() {
      const formProxy = { ...this.form }
      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })
      formProxy.is_active = this.is_active
      if (this.state_date != '' && this.end_date != '') {
        formProxy.start_date =
          this.start_date.split('-')[2] +
          '/' +
          this.start_date.split('-')[1] +
          '/' +
          this.start_date.split('-')[0]
        formProxy.end_date =
          this.end_date.split('-')[2] +
          '/' +
          this.end_date.split('-')[1] +
          '/' +
          this.end_date.split('-')[0]
      } else {
        formProxy.start_date = ''
        formProxy.end_date = ''
      }

      if (this.show_unlock_charge == 0) {
        formProxy.unlock_charge = ''
      }

      if (this.show_reservation_charge == 0) {
        formProxy.reservation_charge = ''
      }

      if (this.show_per_minute_charge == 0) {
        formProxy.per_minute_charge = ''
      }

      if (this.show_free_minutes_each_ride == 0) {
        formProxy.free_minutes_each_ride = ''
      }

      if (formProxy.assigned_fleet == null) {
        delete formProxy.assigned_fleet
      }

      console.log('form = ', formProxy)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? PassConfig.api.update(this.item.id)
        : PassConfig.api.create
      let data = new xMan(formProxy).toFormData()

      let message = this.isEditing
        ? 'Pass updated successfully'
        : 'Pass added successfully'

      this.isLoading = true
      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(PassConfig.events.sorToggle))
        dispatchEvent(new Event(PassConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.form = {}
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `${convertToTitleCase(e.data.code)}`,
            text: e.data.message ?? 'The action could not be executed',
          },
          5000
        )

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
